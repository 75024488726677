import {deleted, getById, getDataByParams, postWithParams, put} from "../services/BaseService";
import {
    articlesUrls,
    caseLawsBaseUrls,
    categoriesUrls,
    lawSummariesBaseUrls,
    lawsUrls,
    ohadaLawsBaseUrls
} from "../util/api_constant";
import {navigate} from "gatsby";
import {AUTH_TOKEN_NAME} from "../services/FetchInterceptor";

export const ErrorHandler = (e: any) => {
    if (e && e.response) {
        if (e.response.status) {
            if (e.response.status === 401) {
                localStorage.removeItem(AUTH_TOKEN_NAME)
                alert('connectedToAccess')
                // alert(t('connectedToAccess'))
                // navigate('/app/login')
            }
            if (e.response.status === 403) {
                // history.push(ENTRY_ROUTE)
                //window.location.reload();
                navigate('/404')
            }
        }
    }
}

export const getOhadarbLaws = (callback: { (value: any): void; (arg0: any): void; }, lang: string) => {
    getDataByParams(`${ohadaLawsBaseUrls.base}?type=ohada-laws&lang=${lang}`).then((res) => {
        callback(res);
    }).catch((e) => {
        console.log(e.message)
        navigate('/404')
    }).finally(() => {
    })
}

export const deleteOhadarbLaws = (id: number) => {
    deleted(`${ohadaLawsBaseUrls.base}/${id}`, undefined).then(() => {
        // alert('item deleted successfully')
        window.location.reload();
    }).catch((e) => {
        console.log(e.message)
        alert('some error handle on server, item not deleted')
    }).finally(() => {
    })
}
export const getLawById = (id: number, callback: { (value: any): void; (arg0: any): void; }) => {
    getById(`${ohadaLawsBaseUrls.base}`, id).then((res) => {
        callback(res);
    }).catch((e:any) => {
        ErrorHandler(e)
    }).finally(() => {
    })
}


export const postOhadarbLaw = (data: any, callback: any) => {
    postWithParams(`${ohadaLawsBaseUrls.base}`, null, data).then((res) => {
        callback(res);
    }).catch((e) => {
        console.log(e.message)
        alert('some error handle on server')
    }).finally(() => {
    })
}


export const putOhadarbLaw = (data: any, callback: any) => {
    put(`${ohadaLawsBaseUrls.base}`, data).then((res) => {
        callback(res);
    }).catch((e) => {
        console.log(e.message)
        alert('some error handle on server')
    }).finally(() => {
    })
}

export const getNationalLaws = (callback: { (value: any): void; (arg0: any): void; }, lang: string) => {
    getDataByParams(`${ohadaLawsBaseUrls.base}`, {lang: lang}).then((res) => {
        callback(res);
    }).catch((e) => {
        console.log(e.message)
        navigate('/404')
    }).finally(() => {
    })
}
export const getNationalLawsbyTypeAndContry = (type: string | null, country: string | null, setLawsData: any, setLoading: any, lang: string) => {
    if (type !== null && country !== null) {
        setLoading(true)
        getDataByParams(`${ohadaLawsBaseUrls.base}`, { // ?type=${type}&lang=${lang}
                type: type,
                lang: lang
            }
        ).then((res) => {
            setLawsData(res?.filter((law: any) => law?.place.includes(country) || law?.treatWith.includes(country)));
            setLoading(false)
        }).catch((e) => {
            setLoading(false)
            console.log(e.message)
        }).finally(() => {
            setLoading(false)
        })
    }
}


export const getCaseLawsCategories = (callback: { (value: any): void; (arg0: any): void; }, lang: string) => {
    // preload: "Laws"
    getDataByParams(`${categoriesUrls.base}`, {type: 'Category', lang: lang}).then((res) => {
        callback(res);
    }).catch((e) => {
        console.log(e.message)
    }).finally(() => {
    })
}

export const postNewCategory = (data: any, callback: any) => {
    postWithParams(`${categoriesUrls.base}`, null, data).then((res) => {
        callback(res);
    }).catch((e) => {
        console.log(e.message)
        alert('some error handle on server')
    }).finally(() => {
    })
}
export const putNewCategory = (data: any, callback: any) => {
    put(`${categoriesUrls.base}`, data).then((res) => {
        callback(res);
    }).catch((e) => {
        console.log(e.message)
        alert('some error handle on server')
    }).finally(() => {
    })
}

export const deleteCategory = (id: number) => {
    deleted(`${categoriesUrls.base}/${id}`, undefined).then(() => {
        window.location.reload();
    }).catch((e) => {
        console.log(e.message)
        alert('some error handle on server, item not deleted')
    }).finally(() => {
    })
}

export const getNationalCourtCaseLawCategories = (country: string, callback: {
    (value: any): void;
    (arg0: any): void;
}, lang: string) => {
    // preload: "CaseLaws"
    getDataByParams(`${categoriesUrls.base}`, {
        type: 'NationalCourtCaseLaw',
        lang: lang,
        country: country
    }).then((res) => {
        callback(res);
    }).catch((e) => {
        console.log(e.message)
    }).finally(() => {
    })
}
export const postNewNationalCourtCaseLawCategory = (data: any, callback: any) => {
    postWithParams(`${categoriesUrls.base}`, null, data).then((res) => {
        callback(res);
    }).catch((e) => {
        console.log(e.message)
        alert('some error handle on server')
    }).finally(() => {
    })
}
export const putNewNationalCourtCaseLawCategory = (data: any, callback: any) => {
    put(`${categoriesUrls.base}`, data).then((res) => {
        callback(res);
    }).catch((e) => {
        console.log(e.message)
        alert('some error handle on server')
    }).finally(() => {
    })
}
export const deleteNationalCourtCaseLawCategory = (id: number) => {
    deleted(`${categoriesUrls.base}/${id}`, undefined).then(() => {
        // alert('item deleted successfully')
        window.location.reload();
    }).catch((e) => {
        console.log(e.message)
        alert('some error handle on server, item not deleted')
    }).finally(() => {
    })
}
export const getNationalCourtCaseLawCategory = (id: number, callback: { (value: any): void; (arg0: any): void; }) => {
    getById(`${categoriesUrls.base}`, id).then((res) => {
        callback(res);
    }).catch((e: any) => {
        ErrorHandler(e)
    }).finally(() => {
    })
}

export const getCaseLawSummaryCategories = (callback: { (value: any): void; (arg0: any): void; }, lang: string) => {
    // preload: "CaseLaws"
    getDataByParams(`${lawSummariesBaseUrls.base}`, {type: 'CaseLawSummary', lang: lang}).then((res) => {
        callback(res);
    }).catch((e) => {
        console.log(e.message)
    }).finally(() => {
    })
}
export const postNewCaseLawSummaryCategory = (data: any, callback: any) => {
    postWithParams(`${lawSummariesBaseUrls.base}`, null, data).then((res) => {
        callback(res);
    }).catch((e) => {
        console.log(e.message)
        alert('some error handle on server')
    }).finally(() => {
    })
}
export const putNewCaseLawSummaryCategory = (data: any, callback: any) => {
    put(`${lawSummariesBaseUrls.base}`, data).then((res) => {
        callback(res);
    }).catch((e) => {
        console.log(e.message)
        alert('some error handle on server')
    }).finally(() => {
    })
}
export const deleteCaseLawSummaryCategory = (id: number) => {
    deleted(`${lawSummariesBaseUrls.base}/${id}`, undefined).then(() => {
        // alert('item deleted successfully')
        window.location.reload();
    }).catch((e) => {
        console.log(e.message)
        alert('some error handle on server, item not deleted')
    }).finally(() => {
    })
}
export const getCaseLawSummaryCategory = (id: number, callback: { (value: any): void; (arg0: any): void; }) => {
    getById(`${lawSummariesBaseUrls.base}`, id).then((res) => {
        callback(res);
    }).catch((e:any) => {

        ErrorHandler(e)
    }).finally(() => {
    })
}
export const getLawCategory = (id: number, callback: { (value: any): void; (arg0: any): void; }) => {
    getById(`${categoriesUrls.base}`, id).then((res) => {
        callback(res);
    }).catch((e:any) => {

        ErrorHandler(e)
    }).finally(() => {
    })
}

export const getCcjaCaseLaws = (callback: { (value: any): void; (arg0: any): void; }, lang: string) => {
    getDataByParams(`${lawsUrls.base}`, {lang: lang}).then((res) => {
        callback(res);
    }).catch((e) => {
        console.log(e.message)
        navigate('/404')
    }).finally(() => {
    })
}

export const getCcjaCaseLawById = (lawId: number, callback: { (value: any): void; (arg0: any): void; }) => {
    getById(`${lawsUrls.base}`, lawId).then((res) => {
        callback(res);
    }).catch((e:any) => {
        ErrorHandler(e)
    }).finally(() => {
    })
}


export const postCcjaCaseLaw = (data: any, callback: any) => {
    postWithParams(`${lawsUrls.base}`, null, data).then((res) => {
        callback(res);
    }).catch((e) => {
        console.log(e.message)
        alert('some error handle on server')
    }).finally(() => {
    })
}

export const putCcjaCaseLaw = (data: any, callback: any) => {
    put(`${lawsUrls.base}`, data).then((res) => {
        callback(res);
    }).catch((e) => {
        console.log(e.message)
        alert('some error handle on server')
    }).finally(() => {
    })
}

export const deleteCcjaCaseLaw = (id: number) => {
    deleted(`${lawsUrls.base}/${id}`, undefined).then(() => {
        // alert('item deleted successfully')
        window.location.reload();
    }).catch((e) => {
        console.log(e.message)
        alert('some error handle on server, item not deleted')
    }).finally(() => {
    })
}

export const getCaseLawById = (lawId: number, callback: { (value: any): void; (arg0: any): void; }) => {
    getById(`${caseLawsBaseUrls.base}`, lawId).then((res) => {
        callback(res);
    }).catch((e:any) => {

        ErrorHandler(e)
    }).finally(() => {
    })
}

export const postCaseLaw = (data: any, callback: any) => {
    postWithParams(`${caseLawsBaseUrls.base}`, null, data).then((res) => {
        callback(res);
    }).catch((e) => {
        console.log(e.message)
        alert('some error handle on server')
    }).finally(() => {
    })
}

export const putCaseLaw = (data: any, callback: any) => {
    put(`${caseLawsBaseUrls.base}`, data).then((res) => {
        callback(res);
    }).catch((e) => {
        console.log(e.message)
        alert('some error handle on server')
    }).finally(() => {
    })
}

export const deleteCaseLaw = (id: number) => {
    deleted(`${caseLawsBaseUrls.base}/${id}`, undefined).then(() => {
        // alert('item deleted successfully')
        window.location.reload();
    }).catch((e) => {
        console.log(e.message)
        alert('some error handle on server, item not deleted')
    }).finally(() => {
    })
}

export const getArticles = (callback: {
    (value: any): void;
    (arg0: any): void;
}, lang: string, archived: boolean = false) => {
    getDataByParams(`${articlesUrls.base}`, {lang: lang}).then((res: any) => {
        callback(res?.filter((article: any) => article.archived === archived));
    }).catch((e) => {
        console.log(e.message)
        navigate('/404')
    }).finally(() => {
    })
}

export const deleteArticle = (id: number) => {
    deleted(`${articlesUrls.base}/${id}`, undefined).then(() => {
        // alert('item deleted successfully')
        window.location.reload();
    }).catch((e) => {
        console.log(e.message)
        alert('some error handle on server, item not deleted')
    }).finally(() => {
    })
}
export const getArticleById = (id: number, callback: { (value: any): void; (arg0: any): void; }) => {
    getById(`${articlesUrls.base}`, id).then((res) => {
        callback(res);
    }).catch((e:any) => {
        ErrorHandler(e)
    }).finally(() => {
    })
}

export const postArticle = (data: any, callback: any) => {
    postWithParams(`${articlesUrls.base}`, null, data).then((res) => {
        callback(res);
    }).catch((e) => {
        console.log(e.message)
        alert('some error handle on server')
    }).finally(() => {
    })
}

export const putArticle = (data: any, callback: any) => {
    put(`${articlesUrls.base}`, data).then((res) => {
        callback(res);
    }).catch((e) => {
        console.log(e.message)
        alert('some error handle on server')
    }).finally(() => {
    })
}
